<template>
  <base-layout mw2>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="primary"/>
          <iframe
            v-show="!isLoading"
            id="iframe"
            :src="docUrl"
            @load="resizeIFrame"/>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import BaseLayout from '@/app/core/components/BaseLayout'

  export default {
    name: 'Payload',

    components: {
      BaseLayout
    },

    props: {
      path: {type: String, default: 'mp/index.html'}
    },

    data () {
      return {
        isLoading: true
      }
    },

    computed: {
      docUrl () {
        return process.env.VUE_APP_DOCS_BASE_URL + this.path
      }
    },

    created () {
      window.addEventListener ('resize', this.resizeIFrame)
    },

    destroyed () {
      window.removeEventListener ('resize', this.resizeIFrame)
    },

    methods: {
      resizeIFrame () {
        document.getElementById ('iframe').style.height =
          `${window.innerHeight - 190}px`

        this.isLoading = false
      }
    }
  }
</script>

<style scoped>
iframe {
  border: none;
  width: 100%;
}
</style>
